export default {
	data() {
		return {
			loading: true,
			getLogManage: {
				page: 1,
				size: 10,
				searchDates:[],
				startUpdateDate: null,
				endUpdateDate: null,
				type: null,
				sortType:"DESC",
				sortTypeName:"createTime"
			},
			tableData: [],
			total: 0
		}
	},
	created() {
		this.getLogManageList();
	},
	methods: {
		// 监听 pagesize 改变的事件
		handleSizeChange(newSize) {
			this.getLogManage.size = newSize;
			this.getLogManageList()
		},
		//监听 页码值 改变的事件
		handleCurrentChange(newPage) {
			this.getLogManage.page = newPage;
			this.getLogManageList()
		},
		doGetLogManageSearch() {
			this.tableData.splice(0, this.tableData.length);			
			this.getLogManage.page = 1;
			if (this.getLogManage.searchDates !== null && this.getLogManage.searchDates.length >
				1) {
				this.getLogManage.startUpdateDate = this.getLogManage.searchDates[0] + ' 00:00:00';
				this.getLogManage.endUpdateDate = this.getLogManage.searchDates[1] + ' 23:59:59';
			} else {
				this.getLogManage.startUpdateDate = '';
				this.getLogManage.endUpdateDate = '';
			}
			this.getLogManageList();
		},
		/*获取日志列表数据*/
		getLogManageList() {
			this.tableData.splice(0, this.tableData.length); //清空数组
			console.log(this.getLogManage)
			this.$axios({
				method: "post",
				url: "/api/sys/web/userAccessLog/page", // 接口地址
				data: this.getLogManage
			}).then(res => {
				if (res.data.code === 200) {
					this.tableData = res.data.data;
					console.log(this.tableData)
					this.totalPages = res.data.pageData.totalPages;
					this.total = res.data.pageData.totalElements;
					this.loading = false;
				} else {
					this.$message({
						message: "操作失败",
						type: "error",
					});
				}
			}).catch(error => {
				console.log(error);
				//this.$message.error("请求失败");
			});
		}
	}
}
